<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">New Budget</span>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-form @submit="triggerSave" class="budgets-form">
            <v-col cols="12">
              <v-text-field
                v-model="budget.year"
                label="Year*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="budget.employeeId"
                :items="usersFormatted"
                chips
                item-text="displayName"
                item-value="authUid"
                label="Employee"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="budget.amount"
                type="number"
                label="Amount*"
                required
              ></v-text-field>
            </v-col>
          </v-form>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- TODO: move buttons within the form -->
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="triggerClose"> Close </v-btn>
      <v-btn color="primary" text type="submit" @click="triggerSave">
        {{ isUpdate ? 'Update' : 'Save' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'BudgetsForm',

  props: {
    itemToEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    budget: {},
    isUpdate: false,
  }),

  computed: {
    ...mapState('users', ['users']),
    usersFormatted() {
      return this.users.map((user) => ({
        ...user,
        displayName: `${user.name} ${user.lastName}`,
      }));
    },
  },

  watch: {
    itemToEdit() {
      this.updateData();
    },
  },

  created() {
    this.bindUsersRef();
    this.updateData();
  },

  methods: {
    ...mapActions('users', ['bindUsersRef']),
    triggerClose() {
      this.$emit('close');
      this.isUpdate = false;
      this.budget = {};
    },
    triggerSave() {
      const mode = this.isUpdate ? 'update' : 'save';
      this.$emit(mode, { collection: 'budgets', formData: this.budget });
      this.isUpdate = false;
      this.budget = {};
    },
    updateData() {
      if (Object.keys(this.itemToEdit).length) {
        this.isUpdate = true;
        this.budget = this.itemToEdit;
      } else {
        this.isUpdate = false;
        this.budget = {};
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.budgets-form {
  width: 100%;
}
</style>
